<template>
  <div
    class="youtube-player-parent"
    :class="{
      'is-blurred': playerBlur,
      'is-mini': !playerFull,
      'is-paused': playerPause,
      'is-tv-styled': options && options.tvStyling,
    }"
  >
    <!-- PLAYER FALLBACK -->
    <!-- <div class="player-fallback"></div> -->

    <!-- PLAYER OUTER -->
    <div class="player-outer">
      <!-- PLAYER INNER (IFRAME PARENT) -->
      <youtube
        class="player-inner embed-responsive embed-responsive-16by9"
        player-width="100%"
        player-height="100%"
        :mute="playerMuteLocal"
        :player-vars="playerVars"
        :style="{ opacity: isPlaying ? 1 : 0 }"
        :video-id="videoId"
        @buffering="onBuffering"
        @ended="onEnded"
        @error="onError"
        @playing="onPlaying"
        @qued="onQueued"
        @ready="onReady"
      >
      </youtube>

      <!-- PLAYER TEXT (MUTE/PAUSE) -->
      <YouTubePlayerText
        v-if="options && options.showPlayerText && playerText"
        :text="playerText"
        :playerPause="playerPause"
        :tv-styling="options && options.tvStyling"
      />

      <!-- PLAYER CONTROLS -->
      <YouTubePlayerControls
        v-if="activeVideo && options && options.controls"
        @muted="playerMuteLocal = !playerMuteLocal"
      />
    </div>
  </div>
</template>

<script>
  // https://github.com/kaorun343/vue-youtube-embed/blob/master/play/Events.vue
  import { mapGetters } from "vuex";
  import YouTubePlayerText from "./YouTubePlayerText.vue";
  import YouTubePlayerControls from "./YouTubePlayerControls.vue";

  export default {
    components: {
      YouTubePlayerControls,
      YouTubePlayerText,
    },
    props: {
      options: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
        DEFAULT_VOLUME: 15,
        isBuffering: null,
        isPlaying: null,
        playerInstance: null,
        playerMuteLocal: this.options?.isMuted ?? false,
        volume: null,
        volumeIntervalInstance: null,
        runningTimeout: null,
      };
    },
    computed: {
      ...mapGetters({
        activeVideo: "youtube/getActiveVideo",
        getActiveTask: "tasks/getActiveTask",
        getActiveVideoIndex: "youtube/getActiveVideoIndex",
      }),
      youTubePlayer() {
        return !!this.getActiveTask("youTubePlayer", "Run");
      },
      isLobbyShowingVideo() {
        return !!this.getActiveTask("lobby", "Show Video");
      },
      playerBlur() {
        return !!this.getActiveTask("youTubePlayer", "Blur");
      },
      playerFull() {
        return !!this.getActiveTask("youTubePlayer", "Full");
      },
      playerLock() {
        return !!this.getActiveTask("youTubePlayer", "Lock"); // TODO: make this universal
      },
      playerMute() {
        return (
          (this.playerVolume && this.playerVolume.data.arg === 0) ||
          !!this.getActiveTask("youTubePlayer", "Mute")
        );
      },
      playerPause() {
        return !!this.getActiveTask("youTubePlayer", "Pause");
      },
      playerSeek() {
        return this.getActiveTask("youTubePlayer", "Seek");
      },
      playerText() {
        return (this.playerPause && "PAUSE") || (this.playerMute && "MUTE");
      },
      playerVars() {
        return {
          autohide: 1,
          autoplay: 1,
          controls: 0,
          disablekb: 1,
          modestbranding: 1,
          rel: 0, // [LOCKED] - related videos, works.
          start: this.activeVideo?.playerVars?.time || 0,
          vq: "hd1080", // work? https://www.embedplus.com/force-high-definition-hd-youtube-embed.aspx
        };
      },
      playerVolume() {
        return this.getActiveTask("youTubePlayer", "Volume");
      },
      videoId() {
        return this.activeVideo?.playerVars?.id;
      },
      videoTitle() {
        return this.activeVideo?.title || "N/A";
      },
    },

    //------------------------------------------------------------
    // WATCH
    //------------------------------------------------------------

    watch: {
      //------------------------------------------------------------
      // WATCH: PLAYER MUTE
      //------------------------------------------------------------

      playerMute(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (!!newVal) {
            this.stopPauseMusic();
            this.setVolume(0, true); // true is no sound fading
          } else {
            const prevVol =
              (this.playerVolume && this.playerVolume.data.arg) || this.DEFAULT_VOLUME;
            this.setVolume(prevVol);
          }
        }
      },

      //------------------------------------------------------------
      // WATCH: PLAYER PAUSE
      //------------------------------------------------------------

      playerPause(newVal, oldVal) {
        !!newVal && newVal !== oldVal ? this.pause() : this.play();
      },

      //------------------------------------------------------------
      // WATCH: PLAYER SEEK
      //------------------------------------------------------------

      playerSeek(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          this.seekTo(newVal.data.arg);
        }
      },

      //------------------------------------------------------------
      // WATCH: PLAYER VOLUME <arg>
      //------------------------------------------------------------

      playerVolume: {
        immediate: true,
        handler(newVal, oldVal) {
          if (!!newVal && newVal !== oldVal) {
            this.setVolume(newVal.data.arg);
          }
        },
      },
    },

    //------------------------------------------------------------
    // CREATED
    //------------------------------------------------------------

    created() {
      // Initialise the player volume depending on if ticket exists.
      this.volume = (this.playerVolume && this.playerVolume.data.arg) || this.DEFAULT_VOLUME;
    },

    //------------------------------------------------------------
    // BEFORE DESTROY
    //------------------------------------------------------------

    beforeDestroy() {
      this.stopPauseMusic();
    },

    //------------------------------------------------------------
    // DESTROY
    //------------------------------------------------------------

    destroy() {
      this.playerInstance.destroy();
      cancelAnimationFrame(this.runningTimeout);
    },

    //------------------------------------------------------------
    // METHODS
    //------------------------------------------------------------

    methods: {
      //------------------------------------------------------------
      // ON READY
      //------------------------------------------------------------

      onReady(event) {
        // Assign instance on player init and set volume.
        this.playerInstance = event.target;
        this.playerInstance.setVolume(this.volume);
      },

      //------------------------------------------------------------
      // ON ENDED
      //------------------------------------------------------------

      onEnded(event) {
        console.info("Video ended.");
        this.next();
      },

      //------------------------------------------------------------
      // ON PLAYING
      //------------------------------------------------------------

      onPlaying(event) {
        this.isPlaying = true;
        this.updatePlayerTime();

        // Clear the TV static now, the video has loaded.
        this.$store.dispatch("tasks/runTasks", [
          { uuid: "749fce6e-7ff6-44d0-868f-a9883a712813", canceled: true },
        ]);

        if (this.playerPause) {
          this.pause();
        }
      },

      //------------------------------------------------------------
      // ON BUFFERING
      //------------------------------------------------------------

      onBuffering() {
        console.info("Video is buffering.");
        this.isPlaying = false;
      },

      //------------------------------------------------------------
      // ON QUEUED
      //------------------------------------------------------------

      onQueued() {
        console.info("Video is queued.");
      },

      //------------------------------------------------------------
      // ON ERROR
      //------------------------------------------------------------

      // http://nicomiceli.com/youtube-embed-tracking-google-analytics/
      onError(error) {
        console.warn("Oops!", this.activeVideo.title);

        let msg = "";

        if (error.data === 2)
          msg = `The request contains an invalid parameter value.
                               For example, the videoID is videoId: ‘M7lc1UVf-VE’ that we declared in step 3 is wrong.
                               Make sure it has 11 total characters and none of them are invalid.`;
        if (error.data === 100)
          msg = "The video requested was not found (removed or marked private)";
        if (error.data === 101 || error.data === 150)
          msg =
            "The owner of the requested video does not allow it to be played in embedded players.";

        // If error, place onto the object to display with playlist display.
        if (msg) {
          // Don't flag these, they can still be played.
          // if (msg === 'The owner of the requested video does not allow it to be played in embedded players.') return;

          // SET FLAG
          this.$store.dispatch("youtube/setFlag", {
            force: true, // to overcome boolean switching
            index: this.getActiveVideoIndex,
            meta: msg,
            type: "error",
          });
        }

        this.next(); // try next video. // todo: this needs to sync up with the playlist
      },

      //------------------------------------------------------------
      // UPDATE PLAYER TIME
      //------------------------------------------------------------

      /*
      updatePlayerTime() {
        const duration = this.playerInstance.getDuration();

        // Update the time on the videoObj, so we can sync the red bar.
        const updateCurrentTime = () => {
          // We're using 1 as the end target, to coincide with scaleX.
          const progress = (this.playerInstance.getCurrentTime() / duration) * 1 + 0.03; // handle anomally

          if (progress.toFixed(4) < 1) {
            this.$emit("player-progress", parseFloat(progress.toFixed(2)));
            this.runningTimeout = requestAnimationFrame(updateCurrentTime);
          }
        };
        this.runningTimeout = requestAnimationFrame(updateCurrentTime);
      },
      */

      /*
      // get rid of the shit
      updatePlayerTime() {
        if (!this.playerInstance) {
          console.warn("Oops! No playerInstance, cannot updatePlayerTime. Consider refreshing?");
          return;
        }

        const duration = this.playerInstance.getDuration();

        clearInterval(this.shitInterval);

        const updateCurrentTime = () => {
          const progress = (this.playerInstance.getCurrentTime() / duration) * 1;

          if (progress < 1) {
            this.$emit("player-progress", progress);
          } else {
            clearInterval(this.shitInterval);
          }
        };

        this.shitInterval = setInterval(updateCurrentTime, 1000);
      },
      */

      updatePlayerTime() {
        if (!this.playerInstance) {
          console.warn("Oops! No playerInstance, cannot updatePlayerTime. Consider refreshing?");
          return;
        }

        const duration = this.playerInstance.getDuration();

        // Update the time on the videoObj, so we can sync the red bar.
        const updateCurrentTime = () => {
          // We're using 1 as the end target, to coincide with scaleX.
          const progress = (this.playerInstance.getCurrentTime() / duration) * 1;

          if (progress < 1) {
            this.$emit("player-progress", progress);
            this.runningTimeout = requestAnimationFrame(updateCurrentTime);
          }
        };
        this.runningTimeout = requestAnimationFrame(updateCurrentTime);
      },

      //------------------------------------------------------------
      // NEXT
      //------------------------------------------------------------

      next() {
        // only allow master to do - place code inside runTasksRemotely
        if (
          window.location.pathname.includes("/all") ||
          window.location.pathname.includes("/background") ||
          window.location.pathname.includes("/bg")
        ) {
          this.$store.dispatch("tasks/runTasksRemotely", [
            { uuid: "8d86f70a-21c1-4431-96f5-d2593ac5b07d" },
          ]); // next
        }
      },

      //------------------------------------------------------------
      // PAUSE
      //------------------------------------------------------------

      pause() {
        this.playerInstance.pauseVideo();
        this.playPauseMusic();
      },

      //------------------------------------------------------------
      // PLAY
      //------------------------------------------------------------

      play() {
        this.playerInstance.playVideo();
        this.stopPauseMusic();
      },

      //------------------------------------------------------------
      // SEEK TO (NEEDS POLISH)
      // https://stackoverflow.com/questions/32799929/youtube-api-seekto-in-hhmmss-format
      //------------------------------------------------------------

      seekTo(target) {
        // target = 0-100
        const duration = this.playerInstance.getDuration(); // Eg. 10s
        // const seconds = ((this.playerInstance.getCurrentTime() / duration) * 1) + 0.03;

        // song is 10s long
        // -->  50(%) is 5s

        // target / duration = 5

        const seconds = duration / (100 / (target - 2.9));

        // console.log('target:', target, 'duration:', duration, 'algo:', seconds);

        // Todo: accept timestamp
        // var a = timecode.split(':');
        // var mul = new Array(1, 60, 3600, 86400);
        // var seconds = 0;

        // for (var x = 0; x < a.length; x++) {
        //     seconds += (a[a.length - 1 - x] * mul[x]);
        // }

        // console.log('SECONDS SEEK', seconds);
        this.playerInstance.seekTo(seconds, true);
      },

      //------------------------------------------------------------
      // SET VOLUME
      //------------------------------------------------------------

      setVolume(volTo, immediate = false) {
        if (this.playerInstance) {
          const oldVolume = this.playerInstance.getVolume();
          const newVolume = volTo; // safety incase deleted vol ticket
          const faderSpeed = 10;

          clearTimeout(this.volumeIntervalInstance);

          // Skip fade, cut straight to volume.
          if (immediate) {
            this.playerInstance.setVolume(newVolume);
            return;
          }

          // Create audio fader.
          this.volumeIntervalInstance = setInterval(() => {
            // Volume up ++
            if (newVolume > oldVolume) {
              if (this.playerInstance.getVolume() < newVolume) {
                this.volume = this.playerInstance.getVolume() + 1;
                this.playerInstance.setVolume(this.volume);
              } else {
                clearInterval(this.volumeIntervalInstance);
              }
            }

            // Volume down --
            else {
              if (this.playerInstance.getVolume() > newVolume) {
                this.volume = this.playerInstance.getVolume() - 1;
                this.playerInstance.setVolume(this.volume);
              } else {
                clearInterval(this.volumeIntervalInstance);
              }
            }
          }, faderSpeed);
        }
      },

      //------------------------------------------------------------
      // STOP
      //------------------------------------------------------------

      stop() {
        this.playerInstance.stopVideo();
        this.stopPauseMusic();
      },

      //------------------------------------------------------------
      // KILL PAUSE SOUND
      //------------------------------------------------------------

      playPauseMusic() {
        this.stopPauseMusic();

        // Play random pause music if *not* muted. If playerVolume doesn't exist, it's default is 15 so run music.
        if (!this.playerVolume || (this.playerVolume && this.playerVolume.data.arg)) {
          const randomPauseMusic = "video-tape-pause-" + String(Math.floor(Math.random() * 2) + 1); // Eg. video-tape-pause-2
          this.$playSound(randomPauseMusic, { volume: 0.2, interrupt: true, loop: true });
        }
      },
      stopPauseMusic() {
        this.$stopSound("video-tape-pause-1");
        this.$stopSound("video-tape-pause-2");
        this.$playSound("video-tape-play", { volume: 0.1, interrupt: true });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .youtube-player-parent {
    position: relative;
    transition:
      filter 2s ease,
      transform 0ms 80ms; // filter was 500ms
  }

  // Not accounting for TV zoom.
  .is-tv-styled.youtube-player-parent {
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: rem-calc(-35); // perfect
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100% - #{rem-calc(80)});
    // border: 1px dashed yellow;
    z-index: -3; // little hack, so plays YT behind lobby -- twitch is stacked on top

    &.is-mini {
      top: 0;
      height: 100%;
    }
  }

  //---------------------------------------------------------
  // PLAYER FALLBACK
  //---------------------------------------------------------

  // .player-fallback {
  //     position: fixed;
  //     bottom: rem-calc(20);
  //     right: rem-calc(35);
  //     width: rem-calc(244);
  //     height: rem-calc(196);
  //     background: black;
  //     transform-origin: bottom right;
  //     transform: rotate(-15deg);
  //     // background: url('./assets/img/tv-static-2.gif') center center;
  //     background: url('./assets/img/test-pattern@2x.jpg') center center;
  //     background-size: 270px;
  //     z-index: -1;
  // }

  //---------------------------------------------------------
  // PLAYER OUTER
  // http://jsfiddle.net/DZTvR/13/
  // https://stackoverflow.com/questions/16549919/is-there-a-way-to-make-the-css-property-mask-image-responsive
  // https://stackoverflow.com/questions/29381545/how-to-make-circle-shaped-video-player-on-safari-browser
  //---------------------------------------------------------

  .player-outer {
    pointer-events: none;
    // border: 1px dashed aqua;
  }

  .is-tv-styled .player-outer {
    // border: 1px dashed aqua;
    width: 100%;
    height: 100%;
    background-color: black; // *NEW* -- Helps with blurring, any issues?

    // This allows TV to remain clear when YT is fullscreen.
    mask: url("./assets/img/fullscreen-mask.svg") bottom -110px right;
    mask-repeat: no-repeat;
    mask-size: 1920px; // TODO: make this big one day for 1440 screens(?)

    transform-origin: center;
    overflow: hidden;
    will-change: transform;
  }

  .is-tv-styled.is-mini .player-outer {
    position: absolute;
    bottom: rem-calc(25);
    right: rem-calc(35);
    width: rem-calc(244);
    height: rem-calc(196);
    mask: none;
    transform-origin: bottom right;
    transform: rotate(-15deg);
  }

  //---------------------------------------------------------
  // PLAYER INNER (IFRAME PARENT)
  //---------------------------------------------------------

  .is-tv-styled .player-inner {
    width: 100%;
    height: 100%;
    // transform: scale(1.05);
    // transform: scale(1.69); <-- this works best with 4:3 content;
    transform: scale(1.09) translateY(25px); // tested with Dan and mitch 1080p
    will-change: transform;

    transition: filter 2s ease;
  }

  .is-blurred .player-inner {
    filter: blur(30px); // for censor AND lobby mask
  }

  .is-tv-styled.is-mini .player-inner {
    transform: scale(1.8); // 1.5 safe?
  }
</style>
